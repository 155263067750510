import  * as types from './mutation-types';

export default{
   [types.RETRIEVE_USER](state, user){
    state.user = user;
   },

   [types.RETRIEVE_USERS](state, users){
      state.users = users;
   },

   [types.ADD_USER](state, user){
      state.users.push({
         id: user.id,
         name: user.name,
         status: user.status,
         email: user.email
      });
   },
   [types.UPDATE_USER](state, user){
      let index = state.users.findIndex((item) => item.id == user.id);
        state.users.splice(index, 1, {
           id: user.id,
           name: user.name,
           email: user.email,
           status: user.status,
        });   
   },

   [types.UPDATE_STATUS](state, user){
      let index = state.users.findIndex((item) => item.id == user.id);
      state.users.splice(index, 1, {
         id: user.id,
         name: user.name,
         email: user.email,
         status: user.status,
      });
   },
   
   [types.DELETE_USER](state, id){
      let index = state.users.findIndex((item) => item.id == id);
        state.users.splice(index, 1);
   },

   [types.CLOSE_MODAL](state){
      state.user.id = 0;
      state.user.name = "";
      state.user.email = "";
      state.user.status = null;
   },
}