export const RETRIEVE_ACTIVIDADES = 'RETRIEVE_ACTIVIDADES';
export const RETRIEVE_ACTIVIDAD = 'RETRIEVE_ACTIVIDAD';
export const SET_ID_ACTIVIDAD = 'SET_ID_ACTIVIDAD';
export const SET_MODAL = 'SET_MODAL';
export const RETRIEVE_IMAGES = 'RETRIEVE_IMAGES';
export const UPDATE_MATERIALES = 'UPDATE_MATERIALES';
export const UPDATE_EQUIPOS = 'UPDATE_EQUIPOS';
export const UPDATE_PERSONAL = 'UPDATE_PERSONAL';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_OBSERVACIONES = 'SET_OBSERVACIONES';