<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>          
        </ion-buttons>
        <ion-title>{{ $route.params.id }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <slot />
      </div>
    </ion-content>
    <ion-footer :translucent="true" mode="md" class="padding">
    <ion-toolbar>      
        <ion-buttons>
          <ion-button color="primary" router-link="/home/Inicio" title="Inicio">          
            <ion-icon slot="icon-only" :ios="homeOutline" :md="homeOutline" :icon="homeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>        
      <ion-buttons slot="end">
          <ion-button color="primary" router-link="/logout" title="Salir">
            <ion-icon slot="icon-only" :ios="logOutOutline" :md="logOutOutline" :icon="logOutOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
    </ion-toolbar>
  </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonFooter,
  IonIcon,
  IonButton
} from "@ionic/vue";
import {logOutOutline, logOutSharp, homeOutline} from 'ionicons/icons';
export default {
  name: "base-layout",
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonButtons,
    IonMenuButton,
    IonFooter,
    IonIcon,
    IonButton
  },
  setup(){
    return{
      logOutOutline, 
      logOutSharp, 
      homeOutline
    }
  }
};
</script>
<style scoped>
#container {
    text-align: center;
    left: 0;
    right: 0;
  }

  #container strong {
    font-size: 20px;
    line-height: 26px;
  }
  
  #container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }
  
  #container a {
    text-decoration: none;
  }
  ion-toolbar{    
      --padding-top: 20px;
  }
</style>