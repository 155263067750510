<template>
  <IonSplitPane content-id="main-content">
    <ion-menu v-if="loggedIn" content-id="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Inicio</ion-list-header>
          <ion-note>{{user.name}}</ion-note>
          <ion-menu-toggle
            auto-hide="false"
            v-for="(p, i) in appPages"
            :key="i"
          >
            <ion-item
              @click="selectedIndex = i"
              router-direction="root"
              :router-link="p.url"
              lines="none"
              detail="false"
              class="hydrated"
              :class="{ selected: selectedIndex === i }"
            >
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <slot />
  </IonSplitPane>
</template>

<script >
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonSplitPane,
} from "@ionic/vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import {
  homeOutline,
  homeSharp,
  powerOutline,
  powerSharp,
  personOutline,
  personSharp,
  enterOutline,
  enterSharp,
  exitOutline,
  exitSharp,
  warningSharp,
  warningOutline
} from "ionicons/icons";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonSplitPane
  },
  mounted(){
   this.getUser();
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "Inicio",
        url: "/home/Inicio",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      },
      {
        title: "Ingreso",
        url: "/entrada/Ingreso",
        iosIcon: enterOutline,
        mdIcon: enterSharp,
      },
      {
        title: "Salida",
        url: "/salida/Salida",
        iosIcon: exitOutline,
        mdIcon: exitSharp,
      },
      {
        title: "Novedad",
        url: "/novedad/Novedad",
        iosIcon: warningOutline,
        mdIcon: warningSharp,
      },
      {
        title: "Perfil",
        url: "/perfil/Perfil",
        iosIcon: personOutline,
        mdIcon: personSharp,
      },
      {
        title: "Salir",
        url: "/logout",
        iosIcon: powerOutline,
        mdIcon: powerSharp,
      },
    ];

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    const route = useRoute();
    return {
      selectedIndex,
      appPages,
      homeOutline,
      homeSharp,
      enterOutline,
      enterSharp,
      exitOutline,
      exitSharp,
      personOutline,
      personSharp,
      powerOutline,
      powerSharp,
      isSelected: (url) => (url === route.path ? "selected" : ""),      
    };
    
  },
  computed:{
    ...mapGetters('user',['user']),
    ...mapGetters('auth',['loggedIn'])
  },
  methods:{
    ...mapActions('user',['retrieveUser']),
    async getUser(){
      const user = await this.retrieveUser();
      return user;
    }
  }
};
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>