<template>
<ion-page>
  <ion-header>
      <ion-toolbar>
        <ion-title>Ingresar</ion-title>        
      </ion-toolbar>
    </ion-header>
      <ion-content :fullscreen="true">
  <login-form></login-form>
</ion-content>
</ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonToolbar, IonTitle} from '@ionic/vue';
import LoginForm from '../components/auth/LoginForm.vue'
export default {
    name:'login-page',
    components:{IonPage, IonHeader, IonContent, LoginForm, IonToolbar, IonTitle}

}
</script>
<style scoped>
ion-toolbar{    
    --padding-top: 30px;
    --padding-bottom: 20px;
}
</style>
