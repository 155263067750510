import router from './router';
import {
    store
} from './store'
import Ls from './services/ls'
import {
    createApp
} from 'vue'

const app = createApp()
    .use(router)
    .use(store)

app._ = require('lodash');

app.Vue = require('vue');

app.axios = require('axios')
app.Ls = Ls;

app.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
};

app.axios.defaults.baseURL = "https://beling.com.co/beling/public/api";

app.axios.interceptors.request.use(
    async (config) => {
            const AUTH_TOKEN = Ls.get('auth.token');
            if (AUTH_TOKEN) {
                config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
            }
            return await config;
        },
        async (error) => {
            // Do something with request error
            return await error;
        }
);

app.axios.interceptors.response.use(undefined, async (err) => {
    const result = await err.response;    
    if (!result) {
        console.log('Network error: Please check your internet connection.');
    } else {
        await result;
        console.log(result);
        if (result.data && (result.statusText === 'Bad Request' ||
                result.data === 'Solicitud incorrecta, ingrese un email o contraseña válidos')) {
            await result.data;
            console.log(result.data.message ? result.data.message : 'No autorizado');
        } else if (result.data && result.statusText === 'No autorizado') {
            await result.data;
            console.log('No autorizado')
        } else if (result.data.errors) {
            const errors = await JSON.parse(JSON.stringify(result.data.errors));
            for (const i in errors) {
                console.log(errors[i])
                await errors[i];

            }
        } else if (result.data.message === 'Unauthenticated.') {
            Ls.remove('auth.token');
            await router.replace('/login')
            await result.data;
            console.log('No se ha autenticado');

        } else {
            await result.data;
            console.log(result.data.message ? result.data.message : 'Error desconocido')
        }
    }
    return result;
});
export default app;