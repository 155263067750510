import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';


const intialState = {
    user: {},
    users:[],
    status:[
        {id: 0, status:"Inactivo"},
        {id: 1, status:"Activo"},
    ]
}

export default {
    namespaced : true,
    state: intialState,
    getters,
    actions,
    mutations
}