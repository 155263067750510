import * as types from './mutation-types';

export default {
   [types.ADD_ENTRADA](state, entrada) {
      state.entradas.push({
         id: entrada.id,
         nombres: entrada.nombres,
         ingreso: entrada.ingreso,
         user_id: entrada.user_id
      });
   },
   [types.ADD_SALIDA](state, salida) {
      state.salidas.push({
         id: salida.id,
         nombres: salida.nombres,
         salida: salida.ingreso,
         user_id: salida.user_id
      });
   },
   [types.UPDATE_ENTRADA](state, entrada) {
      let index = state.entradas.findIndex((item) => item.id === entrada.id);
      state.entradas.splice(index, 1, {
         id: entrada.id,
         nombres: entrada.nombres,
         ingreso: entrada.ingreso,
         user_id: entrada.user_id
      })
   },

   [types.ADD_NOVEDAD](state, novedad) {
      state.novedades.push({
         id: novedad.id,
         fecha: novedad.fecha,
         fechaInicio: novedad.fechaInicio,
         dias: novedad.dias,
         soporte: novedad.soporte
      })
   }
}