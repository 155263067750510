import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
    entrada:{},
    entradas:[],
    salida:{},
    salidas:[],
    novedad:{},
    novedades:[],
    tipoNovedad:[
        {id:1,"tipo":"Incapacidad Eps", "value":"Incapacidad"},
        {id:2,"tipo":"Accidente Laboral", "value":"Accidente"},
        {id:3,"tipo":"Cita Médica", "value":"Cita médica"},
        {id:4,"tipo":"Calamidad Domestica", "value":"Calamidad domestica"},
        {id:5,"tipo":"Otro", "value":"Otro"}
      ]
}

export default {
    namespaced : true,
    state: initialState,
    getters,
    actions,
    mutations
}