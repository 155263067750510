import * as types from './mutation-types';

export default{
    [types.AUTH_SUCCESS](state, token){
        state.token = token;
        state.status = true;
    },

    [types.AUTH_LOGOUT](state) {
        state.token = null,
        state.status = false
      },
    
      [types.AUTH_ERROR](state) {
        state.token = null
        state.status = false
      },
    
      [types.REFRESH_SUCCESS](state, token) {
        state.token = token
        state.status = true
      },

      [types.MESSAGE](state){
        state.message = 'Por aqui voy'; 
      }
}