import axios from 'axios';
import * as types from './mutation-types'

export const retrieveUser = async ({ commit }) => {
  const result = await axios.get("/user");
  if (result.status === 200) {
    await commit(types.RETRIEVE_USER, result.data);
  } else {
    console.log(result);
  }
  return result;
};

  export const retrieveUsers = ({commit}) =>{
    return new Promise((res, rej) =>{
      axios
      .get("/users")
      .then((response) =>{
        commit(types.RETRIEVE_USERS, response.data);
        res(response);
      })
      .catch((error) =>{
        rej(error);
        console.log(error);
      });
    });
  };

  export const register = ({commit}, data) => {    
    return new Promise((res, rej) => {
      axios
        .post("register", {
          name: data.user.name,
          email: data.user.email,
          status: data.user.status,
          clave: data.user.password,
        })
        .then((response) => {          
          commit(types.ADD_USER, response.data);          
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  };

  export const updateUser = ({commit}, data)=>{
    return new Promise((res, rej)=>{
      axios
      .patch("/users/" + data.user.id,{
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        status: data.user.status,
      })
      .then((response) =>{
        commit(types.UPDATE_USER, response.data);
        res(response);
      })
      .catch((error)=>{
        rej(error);
      });
    });
  };

  export const updateStatus = ({commit}, data)=>{    
   let status = data.user.status !== 1 ? 1 : 0;
    return new Promise((res, rej)=>{
      axios
      .patch("/users/" + data.user.id,{
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        status: status,
      })
      .then((response) =>{
        res(response);
        setTimeout(() => {
          commit(types.UPDATE_STATUS, response.data);
        }, 1000);        
      })
      .catch((error)=>{
        rej(error);
      });
    });
  };

  export const deleteUser = ({commit}, id)=>{
    return new Promise((res, rej) => {
      axios
        .delete("/users/" + id)
        .then((response) => {
          commit(types.DELETE_USER, id);
          res(response);
        })
        .catch((error) => {        
          rej(error);
        });
    });
    
  };

  export const  changePasword = async ({commit}, data) =>{     
        const result = await axios.put('/changepassword/'+data.userId,{
          id: data.userId,
          password: data.password
        });
        if(result.status === 200){
          await commit(types.UPDATE_USER, result.data);         
        }
        return result;
  }

  export const closeModal = ({commit})=>{
    commit(types.CLOSE_MODAL);
  };