export const RETRIEVE_ITEMS = 'RETRIEVE_ITEMS';
export const RETRIEVE_ITEM = 'RETRIEVE_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_ITEM_ID = 'SET_ITEM_ID';
export const ADD_ACTIVIDAD = 'ADD_ACTIVIDAD';
export const SET_MATERIALES = 'SET_MATERIALES';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const DEL_ITEM_MATERIAL = 'DEL_ITEM_MATERIAL';
export const RETRIEVE_MATERIAL = 'RETRIEVE_MATERIAL';
export const CLEAR_MATERIAL = 'CLEAR_MATERIAL';
export const SET_EQUIPOS = 'SET_EQUIPOS';
export const ADD_EQUIPO = 'ADD_EQUIPO';
export const DEL_ITEM_EQUIPO = 'DEL_ITEM_EQUIPO';
export const RETRIEVE_EQUIPO = 'RETRIEVE_EQUIPO';
export const CLEAR_EQUIPO = 'CLEAR_EQUIPO';
export const SET_FIRMA_AUTORIZADA = 'SET_FIRMA_AUTORIZADA';
export const SET_FIRMA_INGENIERIA = 'SET_FIRMA_INGENIERIA';
export const RETRIEVE_PERSONAL = 'RETRIEVE_PERSONAL';
export const SET_EMPLEADOS = 'SET_EMPLEADOS';
export const RETRIEVE_MATERIALES = 'RETRIEVE_MATERIALES';
export const RETRIEVE_EQUIPOS = 'RETRIEVE_EQUIPOS';
export const SET_EQUIPO_OLD = 'SET_EQUIPO_OLD';
export const SET_MATERIAL_OLD = 'SET_MATERIAL_OLD';
export const SET_IMAGES = 'SET_IMAGES';
export const QUIT_IMAGE = 'QUIT_IMAGE';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';