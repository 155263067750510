import { createStore } from "vuex";
import user from './modules/user';
import auth from './modules/auth';
import entrada from "./modules/entrada";
import item from './modules/item';
import actividad from './modules/actividad';

export const store = createStore({
    strict: true,
    modules:{
        user,
        auth,
        entrada,
        item,
        actividad
    }
});