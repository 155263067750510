export const items = (state) => state.items;
export const item = (state) => state.item;
export const load = (state) => state.load;
export const actividades = (state) => state.actividades;
export const actividad = (state) => state.actividad;
export const itemId = (state) => state.itemId;
export const materiales = (state) => state.materiales;
export const equipo = (state) => state.equipo;
export const material = (state) => state.material;
export const personal = (state) => state.personal;
export const empleados = (state)=> state.empleados;
export const itemMaterial = (state) => state.itemMaterial;
export const equipos = (state) => state.equipos;
export const itemEquipo = (state) => state.itemEquipo;
export const firmaAutorizada = (state) => state.firmaAutorizada;
export const firmaIngenieria = (state) => state.firmaIngenieria;
export const materialesOld = (state) => state.materialesOld;
export const equiposOld = (state) => state.equiposOld;
export const images = (state) => state.images;