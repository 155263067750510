import * as types from './mutation-types';

export default {
    [types.RETRIEVE_ACTIVIDADES](state, actividades){
        state.actividades = actividades;
    },
    [types.RETRIEVE_ACTIVIDAD](state, id){
        state.actividad = state.actividades.find((item) => item.id === id);
    },
    [types.SET_MODAL](state, modal){
        state.currentModal = modal;
    },    
    [types.RETRIEVE_IMAGES](state, images){
        state.images = images;
    },
    [types.UPDATE_MATERIALES](state, data){
        state.materiales = data;
    },
    [types.UPDATE_EQUIPOS](state, data){
        state.equipos = data;
    },
    [types.UPDATE_PERSONAL](state, data){
        state.personal = data;
    },
    [types.SET_IMAGES](state, images){       
        state.images = images;
    },
    [types.DELETE_IMAGE](state, id){
        let index = state.images.findIndex((image) => image.id === id);
        state.images.splice(index, 1)
    },
    [types.SET_OBSERVACIONES](state, data){
        state.observaciones = data;
    }

}