import axios from "axios";
import * as types from './mutation-types';

export const retrieveItems = async ({commit}) =>{
    const result = await axios.get('/Items');
    if(result.status === 200){
        await commit(types.RETRIEVE_ITEMS, result.data);
    }
}

export const retrievePersonal = async ({ commit })  => {
    const result = await axios.get('/Empleados');
    if(result.status === 200){
        await commit(types.RETRIEVE_PERSONAL, result.data);
    }
};

export const retrieveMateriales = async ({commit}) => {
    const result = await axios.get('/materiales');
    if(result.status === 200){
        await commit(types.RETRIEVE_MATERIALES, result.data)
    }
}

export const retrieveEquipos = async ({commit}) => {
    const result = await axios.get('/equipos');
    if(result.status === 200){
        await commit(types.RETRIEVE_EQUIPOS, result.data)
    }
}

export const retrieveItem = ({commit}, name)=>{
    commit(types.RETRIEVE_ITEM, name);
}

export const addItem = async ({commit}, data) => {
    let resultado = '';
    try {
        const result = await axios.post('/Items',{
            user_id : data.item.userId,
            item: data.item.name,
            description: data.item.description,
            unidad: data.item.unidad,
            cantidad: data.item.cantidad,
            precio_unitario: data.item.precio
        });
        if (result.status === 201){
            await commit(types.ADD_ITEM, result.data);
        }
        resultado = result;
    } catch (err) {
        resultado = err;
    }

    return resultado;
}

export const addActividad = async ({commit}, data) =>{
    let resultado = "";    
    let fechaInicio = data.actividad.fechaInicio === "1969-12-31 19:0:0" ? "" : data.actividad.fechaInicio;
    let fechaFinal = data.actividad.fechaFinal === "1969-12-31 19:0:0" ? "" : data.actividad.fechaFinal;
    let materiales = JSON.stringify(data.actividad.materiales);
    let equipo = JSON.stringify(data.actividad.equipo);
    let personal = JSON.stringify(data.actividad.personal);
    let actividad = "Actividad item: " + data.actividad.actividad + ", Dia: "+ data.actividad.dia;
    try {
        const result = await axios.post('/Actividades',{
            item_id: data.actividad.itemId,
            actividad: actividad,
            dia: data.actividad.dia,
            fecha_inicio: fechaInicio,
            fecha_final: fechaFinal,
            firma_cliente: data.actividad.firmaCliente,
            firma_ingenieria: data.actividad.firmaIngenieria,
            materiales: materiales,
            equipo: equipo,
            personal: personal,
            observaciones: data.actividad.observaciones,
            responsable: data.actividad.responsable,
            documento: data.actividad.documento,
            cargo: data.actividad.cargo,
            proceso: data.actividad.proceso
        });
        if(result.status === 201) {
            await axios.post('/Images',{
                actividad_id : result.data.id,
                images: data.actividad.images
            });
            await commit(types.ADD_ACTIVIDAD, result.data);
        }
        resultado = result;
    } catch (err) {
        resultado = err;
    }
    return resultado;
}

export const setItemId = async ({commit}, id)=>{
    await commit(types.SET_ITEM_ID, id);
}

// ----------------------------------------------------------------
// Start funciones add material
// ----------------------------------------------------------------
export const setMateriales = async ({commit}, materiales)=>{
    await commit(types.SET_MATERIALES, materiales);
}

export const retrieveMaterial = async ({commit}, id)=>{
    await commit(types.RETRIEVE_MATERIAL, id);
}

export const addMaterialItem = async ({commit}, data)=>{    
    await commit(types.ADD_MATERIAL, data.item);
}

export const delItemMaterial = async ({commit}, item)=>{
    await commit(types.DEL_ITEM_MATERIAL, item);
}

export const clearMaterial = async ({commit}) => {
    await commit(types.CLEAR_MATERIAL);
}

// this function set old material list
export const setMaterialesOld = async ({commit}, materiales) => {
    await commit(types.SET_MATERIAL_OLD, materiales);
}

// ----------------------------------------------------------------
// Start funciones add equipo
// ----------------------------------------------------------------
export const setEquipos = async ({commit}, equipos)=>{
    await commit(types.SET_EQUIPOS, equipos);
}

export const retrieveEquipo = async ({commit}, id)=>{    
    await commit(types.RETRIEVE_EQUIPO, id);
}

export const addEquipoItem = async ({commit}, data)=>{    
    await commit(types.ADD_EQUIPO, data.item);
}

export const delItemEquipo = async ({commit}, item)=>{
    await commit(types.DEL_ITEM_EQUIPO, item);
}

export const clearEquipo = async ({commit}) => {
    await commit(types.CLEAR_EQUIPO);
}

// this function set old material list
export const setEquiposOld = async ({commit}, equipo) => {
    await commit(types.SET_EQUIPO_OLD, equipo);
}


export const setFirmaAutorizada = async ({commit}, firma)=>{    
    await commit(types.SET_FIRMA_AUTORIZADA, firma);
}

export const setFirmaIngenieria = async ({commit}, firma)=>{    
    await commit(types.SET_FIRMA_INGENIERIA, firma);
};

//set empleados for select
export const setEmpleados = async({commit}, empleados)=>{
    await commit(types.SET_EMPLEADOS, empleados);
};

//Images

export const setImages = async({commit}, image)=>{
    await commit(types.SET_IMAGES, image);
}

export const quitImages = async({commit}, image) =>{
    await commit(types.QUIT_IMAGE, image);
};

export const clearImages = async({commit}) => {
    await commit(types.CLEAR_IMAGES);
};
