import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
    items:[],
    item: {},
    load : false,
    actividades: [],
    actividad:{},
    itemId:{},
    materiales:[],
    equipos:[],
    itemMaterial: {},
    itemEquipo: {},
    firmaAutorizada:null,
    firmaIngenieria: null,
    personal:[],
    empleados:[],
    equipo:[],
    material:[],
    materialesOld:[],
    equiposOld:[],
    images:[]
}

export default{
    namespaced : true,
    state: initialState,
    mutations,
    actions,
    getters
}