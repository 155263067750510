import { createRouter, createWebHistory } from '@ionic/vue-router';
import MemoriesPage from '../pages/MemoriesPage.vue';
import Login from '../pages/LoginPage.vue';
import { store } from "../store";

const routes = [  
  {
    path: '/',
    redirect: '/home/Inicio'   
  },
  {
    path:'/home/:id',
    component: () => import('../pages/Home.vue'),
    name: 'home',
    meta:{
      requiresAuth: true,
    }
  },
  {
    path:'/entrada/:id',
    component: () => import('../pages/EntradaPage.vue'),
    name:'entrada',
    meta:{requiresAuth:true}
  },
  {
    path:'/salida/:id',
    component: () => import('../pages/SalidaPage.vue'),
    name:'salida',
    meta:{requiresAuth:true}
  },
  {
    path:'/actividad/:id',
    component:() => import('../pages/ActividadPage.vue'),
    name:'actividad',
    meta:{requiresAuth:true}
  },
  {
    path:'/add-actividad/:id',
    component: () => import('../pages/AddActividadPage.vue'),
    name:'add_actividad',
    meta:{requiresAuth:true}
  },
  {
    path:'/login',
    component: Login,
    name:'login'
  },
  {
    path:'/memories',
    component: MemoriesPage,
    name:'memories',
    meta:{requiresAuth:true}
  },
  {
    path: '/novedad/:id',
    component: () => import('../pages/NovedadPage.vue'),
    name:'novedad',
    meta:{requiresAuth: true}
  },
  {
    path:'/items/:id',
    component: () => import('../pages/ItemsPage.vue'),
    name:'items',
    meta:{requiresAuth:true}
  },
  {
    path: '/additem/:id',
    component: () => import('../pages/ItemPage.vue'),
    name:'additem',
    meta:{requiresAuth:true}
  },
  {
    path: '/item/:id',
    component: () => import('../pages/ItemDetailPage.vue'),
    name:'item',
    meta:{requiresAuth:true}
  },  
  {
    path:'/perfil/:id',
    component: () => import('../pages/PerfilPage.vue'),
    name:'perfil',
    meta:{requiresAuth:true}
  },
  {
    path:'/password/:id',
    component: () => import('../pages/ChangePasswordPage.vue'),
    name:'change-password',
    meta:{requiresAuth:true}
  },
  {
    path: '/memories/:id',
    component: () => import('../pages/MemoryDetailPage.vue'),
    name:'memory',
    meta:{requiresAuth:true}
  },
  {
    path: '/memories/add',
    component: () => import('../pages/AddMemoryPage.vue'),
    name:'memoryadd',
    meta:{requiresAuth:true}
  },
  {
    path: '/logout',
    component: () => import('../components/auth/Logout.vue'),
    name: 'logout'
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["auth/loggedIn"]) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters["auth/loggedIn"]) {
      next({
        name: "home",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router
