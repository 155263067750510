import axios from "axios";
import * as types from './mutation-types';

// This function retrieve actividades from api
export const retrieveActividades = async ({commit},idItem) =>{
    //let resultado = "";
    const result = await axios.get('/Actividades/' + idItem);
    if(result.status === 200) {
        await commit(types.RETRIEVE_ACTIVIDADES, result.data);
    }
}

/**
 * 
 * @param {commit} commit 
 * @param {data} data 
 * @returns update materials, status 200
 */
export const updateMateriales = async({commit},data) => {
    let resultado = '';
    let materiales = JSON.stringify(data.materiales);
    
    
     try {
        const result = await axios.patch('Actividades-material/'+data.id,{
            id: data.id,
            materiales : materiales,
        });

        if(result.status === 200){
            await commit(types.UPDATE_MATERIALES, data);
        }

        resultado = result;

    } catch (err) {
        resultado = err;
    }
    return resultado;
 
};

/**
 * 
 * @param {import("vuex").Commit} commit 
 * @param {Object} data 
 * @returns  update equipos in bd, return status 200 ok update equipos
 */

export const updateEquipos = async({commit},data) => {   
    let equipos = JSON.stringify(data.equipos);       
     try {
        const result = await axios.patch('/Actividades-equipo/'+data.id,{
            id: data.id,
            equipo : equipos,
        });

        if(result.status === 200){
            await commit(types.UPDATE_EQUIPOS, data);
        }

        return result;
          
    } catch (err) {
        return err;
    }  
 
};

export const updatePersonal = async ({commit},data)=>{    
    let resultado = '';
    let personal = JSON.stringify(data.empleados);
    try {
        const result = await axios.patch('/Actividades-personal/' + data.id,{
            id: data.id,
            personal : personal,
        });

        if(result.status === 200){
            await commit(types.UPDATE_PERSONAL, data)
        }

        resultado = result;
          
    } catch (err) {
        resultado = err;
    }
    return resultado;
    
};

export const updateObservaciones = async ({commit},data) => {
    let resultado = '';
    
    try {
        const result = await axios.patch('/Actividades-observaciones/'+ data.id,{
            id: data.id,
            observaciones: data.observaciones
        });

        if(result === 200){
            await commit(types.SET_OBSERVACIONES, data.observaciones)
        }

        resultado = result

    } catch (err) {
        resultado = err;
    }
    return resultado;
};

//Images

// This funcion retrieves images for each actividad
export const retrieveImages = async ({commit}, id)=>{
    const result = await axios.get(`/Images/${id}`);
    if(result.status === 200){
        await commit(types.RETRIEVE_IMAGES, result.data);
    }
}

export const saveImages = async({commit}, data) => {
    let resultado = '';
    try {
        const result = await axios.post('/Images',{
            actividad_id : data.id,
            images: data.images
        });
        if(result === 201){
            await commit(types.SET_IMAGES, []);
        }
        resultado = result;
    } catch (err) {
        resultado = err
    }
    return resultado;
};

/**
 * 
 * @param {import("vuex").Commit} commit 
 * @param {Number} id 
 * @returns axios response from database
 * 
 */

export const deleteImage = async ({commit}, id)=>{
    let resultado;
    try {
        const result = await axios.delete(`/Images/${id}`);
        if(result.status === 200){
            await commit(types.DELETE_IMAGE, id);
        }

        resultado = result;
        
    } catch (err) {
        resultado = err;
    }
    
    return resultado;
};

export const setObservaciones = async({commit}, data) =>{
    await commit(types.SET_OBSERVACIONES, data);
};

//This function retrieve one actividad from actividades
export const retrieveActividad = async({commit}, id)=>{
    await commit(types.RETRIEVE_ACTIVIDAD, id)
}

// This function set del modal state
export const setModal = async({commit}, modal)=>{
    await commit(types.SET_MODAL, modal);
}
