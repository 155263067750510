import * as types from './mutation-types';

export default {

    //Item types
    [types.RETRIEVE_ITEMS](state,items){
        state.items = items;
    },
    [types.RETRIEVE_ITEM](state, id){
        state.item = state.items.find((item) => item.id === id);
        state.load = true;
        
    },
    [types.ADD_ITEM](state, item) {
        state.items.push({
            id: item.id,
            item: item.item,
            unidad: item.unidad,
            description: item.description,
            cantidad: item.cantidad,
            precio : item.precio_unitario
        });
    },
    [types.UPDATE_ITEM](state, item) {
        let index = state.items.findIndex((item) => item.id == item.id);
        state.items.splice(index, 1, {
            id: item.id,
            item: item.item,
            unidad: item.unidad,
            description: item.description,
            cantidad: item.cantidad,
            precio : item.precio_unitario
        })
    },
    [types.DELETE_ITEM](state, id) {
        let index = state.items.findIndex((item) => item.id == id);
        state.items.splice(index, 1);
    },
    [types.SET_ITEM_ID](state,id){
        state.itemId =id;
    },

    //Actividad types
    [types.ADD_ACTIVIDAD](state, actividad){
        state.actividades.push({
            id: actividad.id,
        })
    },
    [types.SET_FIRMA_AUTORIZADA](state, firma){
        state.firmaAutorizada = firma;
    },
    [types.SET_FIRMA_INGENIERIA](state, firma){
        state.firmaIngenieria = firma;
    },

    //Personal types
    [types.RETRIEVE_PERSONAL](state, personal){
        state.personal = personal;
    },
    [types.SET_EMPLEADOS](state, empleados){
        state.empleados = empleados
    },
    //Material types
    [types.RETRIEVE_MATERIALES](state, data){
        state.material = data;
    },
    [types.RETRIEVE_MATERIAL](state, id) {
        state.itemMaterial = state.material.find((item) => item.id === id);
    },
    [types.SET_MATERIALES](state, materiales){
        state.materiales = materiales;
    },
    [types.ADD_MATERIAL](state, item){
        state.materiales.push({
        id: item.id,
        name: item.name,
        cantidad: item.cantidad
    });
    },
    [types.CLEAR_MATERIAL](state){
        state.materiales = [];
    },
    [types.DEL_ITEM_MATERIAL](state, id){
        let index = state.materiales.findIndex((item) => item.id ==id);
        state.materiales.splice(index, 1);
    },
    [types.SET_MATERIAL_OLD](state, materiales){
        state.materialesOld = materiales;
    },

    //Equipo types
    
    [types.RETRIEVE_EQUIPOS](state, data){
        state.equipo = data;
    },    
    [types.RETRIEVE_EQUIPO](state, id){
        state.itemEquipo = state.equipo.find((item) => item.id === id);
    },
    [types.SET_EQUIPOS](state, equipos){
        state.equipos = equipos;
    },
    [types.ADD_EQUIPO](state, equipo) {
        state.equipos.push({
            id: equipo.id,
            name : equipo.name,
            cantidad : equipo.cantidad
        })
    },
    [types.CLEAR_EQUIPO](state) {
        state.equipos = [];
    },
    [types.DEL_ITEM_EQUIPO](state, id){
        let index = state.equipos.findIndex((item) => item.id === id);
        state.equipos.splice(index, 1);
    },
    [types.SET_EQUIPO_OLD](state, equipo){
        state.equiposOld = equipo;
    },
    // Images
    [types.SET_IMAGES](state, image){
        state.images.push(image);
    },
    [types.QUIT_IMAGE](state, image){
        let index = state.images.findIndex((img) => img == image);
        state.images.splice(index, 1);
    },
    [types.CLEAR_IMAGES](state){
        state.images = [];
    }
}