<template>
  <ion-app>
    <base-menu>
      <ion-router-outlet id="main-content" />
    </base-menu>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import BaseMenu from './components/base/BaseMenu.vue';

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    BaseMenu
  }
}
</script>