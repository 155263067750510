import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const initialState = {
    actividades:[],
    actividad:{},
    idActividad:0,
    currentModal: null,    
    images:[],
    observaciones:'',
}

export default{
    namespaced : true,
    initialState,
    actions,
    getters,
    mutations
}