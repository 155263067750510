import Ls from '@/services/ls'
import axios from 'axios';
import * as types from './mutation-types'
import router from '@/router/index'

export const retrieveToken = async ({
  commit
}, credentials) => {
  let loginData = {
    username: credentials.email,
    password: credentials.password
  }
  const result = await axios.post('/login', loginData);
  if (result.status === 200) {
    const token = result.data.access_token;
    await Ls.set('auth.token', token);
    await commit(types.AUTH_SUCCESS, token);     
  } else {
    await commit(types.AUTH_ERROR, result)
    await Ls.remove('auth.token')   
  }
  return result.status;
};

export const destroyToken = async ({
  commit
}, noRequest = false) => {
  if (noRequest) {
    await commit(types.AUTH_LOGOUT)
    await Ls.remove('auth.token')
    await router.replace('/login')
    return true
  }
  const result = await axios.post('/logout');
  if (result.status === 200) {
    await commit(types.AUTH_LOGOUT)
    await Ls.remove("access_token");
    await router.replace('/login')
    return result;
  } else {
    await commit(types.AUTH_LOGOUT)
    await Ls.remove('auth.token')
    await router.push('/login')
    return result;
  }
};